import "./libs.bundle.js";
import "./jquery-bootstrap";
import "./ducky/class.js";
import "./ducky/polyfill.js";
import "./lib/moment.js";
import "./ducky/provide.js";
import "./ducky/translate.js";
import "./ducky/form/formatters.js";
import "./ducky/format.js";
import "./ducky/utils/number_format.js";
import "./ducky/utils/utils.js";
import "./ducky/utils/common.js";
import "./ducky/ui/messages.js";
import "./ducky/utils/pretty_date.js";
import "./ducky/utils/datetime.js";
import "./ducky/microtemplate.js";
import "./ducky/query_string.js";

import "./ducky/upload.js";

import "./ducky/model/meta.js";
import "./ducky/model/model.js";
import "./ducky/model/perm.js";

import "./bootstrap-4-web.bundle";

import "../../website/js/website.js";
import "./ducky/socketio_client.js";
